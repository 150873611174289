<template>
  <div id="app">
    <div class="head">
      <h1 class="top">
        <img src="./assets/logo.png" alt="问答蟹" />文答蟹
        (个人图文分享，一起交流吧)<!-- <a
          href="http://175.178.114.30:8866"
          target="_blank"
          >网站完善中...</a -->
        <!-- href="http://175.178.114.30:9000/app/app-wdx.apk" -->
      </h1>
    </div>

    <div class="content">
      <contentVue v-if="flag"></contentVue>
      <div v-if="!flag">测试ing...</div>
    </div>
    <footVue />
  </div>
</template>

<script>
import footVue from "./components/footVue.vue";
import contentVue from "./components/contentVue.vue";
export default {
  name: "App",
  components: {
    footVue,
    contentVue,
  },
  data() {
    return {
      flag: null,
    };
  },
  mounted() {
    if (this.isMobile()) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  metaInfo: {
    title: "问答蟹", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content:
          "出处君,枫可怜,番号,禾野,黑肉,问答蟹,蚊香社,女优,类似小红书,类似微博",
      },
      {
        name: "description",
        content: "问答蟹，分享生活和学习的平台，可以在这里提问或解答或讨论",
      },
    ],
    link: [
      {
        // set link
        rel: "shortcut icon",
        href: "/assets/logo.png",
      },
    ],
  },
};
</script>

<style >
/* @media screen and (width: 430px) and (height: 932px) and (-webkit-device-pixel-ratio: 3) {
  #app {
    width: 100%;
  }
} */
/* @media screen and (min-width: 431px) {
  #app {
    width: 80%;
  }
} */
html,
body {
  background: rgb(233, 247, 247);
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}
#app {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.head {
  display: flex;
  justify-content: space-between;
}

img {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
.top {
  font-size: larger;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  height: 20px;
}
.content {
  /* background-color: lightblue; */
  flex: 1;
}
</style>
