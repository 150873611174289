<template>
  <div class="foot">
    <div class="emwct">
      <div class="ewm">
        <img class="tp" src="../assets/wdxercode.jpg" alt="" />微信:问答蟹
      </div>
      <div class="ewm">
        <img class="tp" src="../assets/weibo.png" alt="" />微博:问答蟹
      </div>
      <div class="ewm">
        <img class="tp" src="../assets/douyin.png" alt="" />抖音:问答蟹
      </div>
      <div class="ewm">
        <img class="tp" src="../assets/qqkf.png" alt="" />QQ
      </div>
    </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >赣公网安备36100202000495号</a
      >|<a href="https://beian.miit.gov.cn/" target="_blank"
        >赣ICP备2024019993号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "footVue",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: black;
  font-size: 10px;
}
.foot {
  text-align: center;
  margin: 0 auto;
}
.emwct {
  display: flex;
  flex-direction: row;
  /* margin: 0 auto; */
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
}
.ewm {
  /* left: -50%; */
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 100%;
}
.tp {
  width: 56px;
  height: 56px;
}
</style>
