<template>
  <div class="cont">
    <button class="item" @click="downapk">APK</button>
    <button class="item" @click="gotoh5"><a>H5</a></button>
  </div>
</template>
<script>
export default {
  methods: {
    downapk() {
      window.open("http://175.178.114.30:9000/app/app-wdx.apk");
    },
    gotoh5() {
      window.open("http://175.178.114.30:8866", "_blank");
    },
  },
};
</script>
<style >
.cont {
  padding-top: 50px;
}
.item {
  display: block;
  font-size: x-large;
  color: white;
  margin: 30px auto;
  /* margin-top: 50px; */
  height: 50px;
  width: 60%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(116, 112, 112);
  background-image: linear-gradient(
    to right,
    #fbc2eb 0%,
    #a6c1ee 51%,
    #fbc2eb 100%
  );
}
</style>