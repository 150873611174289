import Vue from 'vue'
import App from './App.vue'
import MetaInfo from 'vue-meta-info';
import "@/util/flexible";

Vue.config.productionTip = false
Vue.use(MetaInfo)
new Vue({
  render: h => h(App),
}).$mount('#app')
